import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"
import TLKEP from "images/TLKEP.svg"
import {Container} from "reactstrap"
import {Link} from 'gatsby'

const Wrapper = styled.div`
    margin: 0 auto 2rem;
    max-width: 1600px;
    width: 100%;
    background-color: ${props => props.theme.colors.yellow};
    text-align: center;
    padding: 1rem 0;
    position: relative;
    z-index: 2;
    
    @media ${media.md} {
        padding: 1rem 0;
        text-align: left;
    }
    
    .container {
        position: relative;
    }
    
    img {
        width: 260px;
        
        @media ${media.md} {
            width: 300px;
        }
    }
    
    h1 {
        margin-bottom: 0;
        margin-top: 1rem;
        
        @media ${media.md} {
            margin-top: 0;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
        
    }
`

class HeaderCopy extends Component {
    render() {
        return (
            <Wrapper>
                <Container fluid={true}>
                    <Container>
                        <Link to="/"><img src={TLKEP} alt="The Lion King - Education Programme"/></Link>
                        <h1 dangerouslySetInnerHTML={{__html: this.props.title}}/>
                    </Container>
                </Container>
            </Wrapper>
        );
    }
}

export default HeaderCopy;